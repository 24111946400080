@import '../../styles/responsiveness';

.blogs {
    margin-top: 10rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    div {
        width: 80%;
        max-width: 200rem;

        margin-bottom: 150px;

        img {
            width: 100%;
            margin-bottom: 30px;

            overflow: hidden;

            @include respond(small-phone) {
                height: auto;
            }
        }

        h2 {
            margin-bottom: 20px;
            font-weight: bold;
        }

        h6 {
            line-height: 2.9rem;
        }

        .upload-date {
            margin-top: 20px;
            font-weight: 500;
        }

        &:hover {
            cursor: pointer;
        }
    }
}