@import '../../styles/responsiveness';

.home-page {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-content: stretch;


    padding-left: 7rem;
    padding-bottom: 7rem;

    @include respond(phone) {
        flex-direction: column-reverse;
        align-items: flex-end;

        padding-left: 1rem;
        padding-bottom: 2rem;

        overflow: hidden;
    }

    div {

        width: fit-content;

        display: flex;
        flex-direction: column;
    
        justify-content: flex-end;
        align-content: flex-end;

        @include respond(phone) {
            align-self: flex-start;
        }

        h2 {

            @include respond(phone) {
                font-size: 10rem;
            }

            @include respond(small-phone) {
                font-size: 8rem;
            }

            @include respond(very-small) {
                font-size: 6.5rem;
            }

            // transition: all .6s;

            // &:hover {
            //     cursor: pointer;

            //     body {
            //         backdrop-filter: blur(20px);
            //     }
            // }
        
        }
    }

    .left-content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        padding-right: 6rem;

        @include respond(phone) {
            padding-right: 3rem;
            align-self: flex-end;
        }

        svg {
            fill: #0077B6;

            width: 4rem;
            height: 4rem;

            transition: all .4s;

            &:first-child {
                margin-bottom: 7rem;
            }

            &:hover {
                cursor: pointer;
                transform: scale(1.1);
            }
        }
    }
}