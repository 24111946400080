@import '../../styles/responsiveness';

.about {
    display: flex;
    justify-content: center;

    h6 {
        text-align: center;

        margin-top: 10rem;
        max-width: 100rem;

        line-height: 4rem;
        letter-spacing: .1rem;

        color: #000;

        @include respond(tab-port) {
            padding: 20px 40px;
        }

    }
}