@import '../../styles/responsiveness';

.skills {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  margin-top: 10rem;

  position: relative;

  div {
    width: 30rem;

    ul {
      @include respond(phone) {
        display: block;
        margin-bottom: 120px;
      }

      li {
        list-style: none;
        padding-bottom: 10px;
      }
    }
  }

  .experince {
    position: absolute;
    bottom: 15rem;
    right: 50%;

    transform: translateX(50%);

    font-weight: 500;
    font-size: 2rem;
  }
}
