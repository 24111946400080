.navbar {
  width: 100%;
  height: 10%;

  padding: 2rem;

  display: flex;
  justify-content: center;

  backface-visibility: hidden;
  overflow: hidden;

  h6 {
    padding: 1rem 3rem;

    transition: all .6s .1s;

    &:hover {
      cursor: pointer;
      // margin: 0 10%;
    }

  }
}

.bold {
  font-weight: 600;
}
