// Font Family
@mixin quicksand-font {
  font-family: 'Quicksand', sans-serif;
}

@mixin bungee-font {
  font-family: 'Bungee', cursive;
}

@mixin bungeeinline-font {
  font-family: 'Bungee Inline', cursive;
}


// Typography texts
.main-heading {
  @include bungeeinline-font;

  font-size: 10rem;
  line-height: 14rem;
  letter-spacing: 1rem;

  color: #023E8A;
  font-weight: 300;
}

.secondary-heading {
  @include quicksand-font;

  font-size: 2.2rem;
  font-weight: 400;
  color: #023E8A;
}

.tertiary-heading {
  @include quicksand-font;

  font-size: 1.8rem;
  font-weight: 300;
  color: #023E8A;

  letter-spacing: 1.5px;
}



// animation: smoothCome .6s .5s ease-in;

// @keyframes smoothCome {
//   from {
//     opacity: 0;
//     transform: translateY(-20px);
//   }

//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }